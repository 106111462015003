
export default {
    name: 'ProductInfo',
    props: {
        currency: Object,
        middleEmit: Object,
        info: Object,
        active: Object,
        review: Object,
        skuSelect: Object,
    },
    data() {
        return {
            rsInfo: {},
            hasZip: false,
            tag: {
                visible: false,
                list: []
            },
            discount: {
                discountRate: '',
                rateText: ''
            },
            productInfo: {},
            price: {
                displayAmount: 0,
                displayOrgPrice: 0,
                displaySalePrice: 0,
                displayZipPrice: 0,
                orgPrice: 0,
                zipPrice: 0,
                cnvAmount: 0,
            },
            shareCompo: {
                visible: false,
                hasClose: false,
                productInfo: {}
            },
            breadcrumpList:[]
        }
    },
    watch: {
        info: {
            handler(n, o) {
                this.setData()
                this.setPrice()
                this.setZipStatus()
                this.setTag()
            },
            deep: true,
            immediate: true
        },
        skuSelect: {
            handler(n, o) {
                this.emitSkuSet()
            },
            deep: true,
        }
    },
    mounted() {
        this.initialSet()
    },
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
            // 设置面包屑
            this.setBread()
            // 设置折扣
            this.setDiscount()
        },
        setBread(){
            if(this.rsInfo?.spuCate&&this.rsInfo?.spuCate?.length>=1){
                this.breadcrumpList = [{
                    name: 'Home',
                    href: '/',
                }]
                this.rsInfo?.spuCate?.map(res=>{
                    this.breadcrumpList.push({
                        name:res.menuName,
                        href:res.jumpUrl,
                    })
                })
            }
        },
        // 货币金额转换
        setCcyCnvAjax() {
            return new Promise((resolve, reject) => {
                this.$api.common.setCcyCnv({
                    amounts: this.price.cnvAmount
                }).then(response => {
                    const res = response?.result || [];
                    resolve(res)
                })
            })
        },
        // 折扣设置
        setDiscount() {
            const select = this.skuSelect || {};
            this.discount.rateText = ''
            if (select.hasAllSelect) {
                this.discount.discountRate = select.discountRate || 0
            } else {
                this.discount.discountRate = this.rsInfo.discountRate || 0
            }
            this.discount.discountRate && (this.discount.rateText = `-${this.discount.discountRate}%`)
        },
        // 金额数据设置
        setPrice() {
            this.price.orgPrice = this.rsInfo.orgPrice
            this.price.salePrice = this.rsInfo.salePrice
            this.price.displayAmount = this.rsInfo.displaySalePrice
            this.price.displaySalePrice = this.rsInfo.displaySalePrice
            this.price.displayOrgPrice = this.rsInfo.orgPrice > this.rsInfo.salePrice ? this.rsInfo.displayOrgPrice : 0
        },
        setPriceEmit() {
            this.middleEmit.fn('setPrice', this.price)
        },
        // 设置商品标签
        setTag() {
            this.tag.list = []
            this.rsInfo?.productLabel && this.rsInfo?.productLabel.some(item => {
                this.tag.list.push({
                    ...item,
                    style: `color: ${item.color};background: ${item.backgroundColor};padding: 0 2px;`
                })
                return true
            })
            this.tag.visible = this.tag.list.length
        },
        // zip操作
        setZipStatus() { // zip状态
            if (this.rsInfo.isSupportZip == 1) {
                this.price.zipPrice = this.rsInfo.salePrice
                this.price.displayZipPrice = this.rsInfo?.zipInfo?.displayInstalmentAmount
                this.hasZip = true
            }
            this.setPriceEmit()
        },
        setZipPrice() { // 设置zip金额
            if (this.rsInfo.isSupportZip != 1) {
                this.setPriceEmit()
                return
            }
            const select = this.skuSelect?.select || {};
            const targetPrice = select.hasAllSelect ? select.marketPrice : this.rsInfo.salePrice
            if (targetPrice != this.price.zipPrice) {
                this.price.zipPrice = targetPrice
                this.price.cnvAmount = targetPrice
                this.setCcyCnvAjax().then(response => {
                    const res = response[0] || {}
                    res.displayResultAmount && (this.price.displayZipPrice = res.displayResultAmount)
                    this.setPriceEmit()
                });
            }
        },
        handleZip() { // 提示
        },
        // 活动点击
        handleShowActivePopup() {
            this.middleEmit.fn('showActivePopup', {
                visible: true,
                type: 1
            })
        },
        // sku变更
        emitSkuSet() {
            const select = this.skuSelect || {};
            if (select.hasAllSelect) {
                this.productInfo = select
                this.price.displayAmount = select.displayMarketPrice
                this.price.displayOrgPrice = select.costPrice > select.marketPrice ? select.displayCostPrice : 0
                this.price.orgPrice = select.costPrice
                this.price.salePrice = select.marketPrice
                this.rsInfo.stagesInfo = select.stagesInfo
            } else {
                this.productInfo = this.rsInfo
                this.setPrice()
            }
            this.setZipPrice()
            this.setDiscount()
        },
        handleRate() {
            this.$VueScrollTo.scrollTo('.ProductReview', '', {
                offset: -60
            })
        },
        // 分享
        handleShare() {
            let img = this.rsInfo.pics && this.rsInfo.pics[0] && this.rsInfo.pics[0].url;
            let href = location.href;
            let url = href.replace('m.yfn', 'www.yfn');
            url = url.replace('mmall.', 'pcmall.')
            this.shareCompo = {
                visible: true,
                url,
                title: this.rsInfo.productName,
                description: this.rsInfo.seoUrl,
                img,
                productInfo: this.rsInfo
            }
        },
        handleCloseShare() {
            this.shareCompo.visible = false
        },
        // 初始化设置
        initialSet() { }
    },
}
